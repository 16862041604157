<script>
    // import totalItems

</script>
<section class="deals-section">
    <img src="https://giftst.smartlifestyletrending.com/gadgets/mybanner.jpg" alt="deals" />
    <p>As we get into Fall and Winter, you can look forward to trying out fresh pumpkin pies, Halloween, and Thanksgiving - and trying out the hottest gadgets of the year, handpicked by our editorial team.</p>
    <p>So as we prep ourselves for spending more time indoors, here’s our favorite hot picks that’ll make your life easier through these colder months.</p>
    <p>Most of these genius gadgets are under $100 and most of them are in limited supply. Plus, most of them are exclusive to our site - which means you won't be able to find them anywhere else.</p>
    <p>So if one (or a few) of these hot gadgets catch your eye, make sure you grab them quickly before they run out!</p>
</section>

<style>
    .deals-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    img {
        vertical-align: middle;
    }

    p {
        font-size: 24px;
        line-height: 40px;
        font-weight: 300;
    }
</style>