import './app.css'
import App from './App.svelte'

// Dynamically load external script
const script = document.createElement('script');
script.src = "https://rt.smarttrendsnow.com/track.js";
script.type = "text/javascript";
document.head.appendChild(script);


const app = new App({
  target: document.getElementById('app'),
})

export default app
