<script>
    export let offerNumber,offerName, offerLink, offerTitle, offerReview, offerStar, offerImage, offerSummary;
    export let OfferLenght; 

    // console.log("NewArrivalsFlag in CtaSection:", NewArrivalsFlag);
    if(offerStar > 5){ offerStar = 5;}
</script>
<section class="bonus-section">
    {#if offerNumber === 0}
        <p class="new-arrivals">-- -- NEW ARRIVALS -- --</p>
    {:else}
        <div class="number-title">
            <p class="title">
                {#if offerNumber > 0}
                    <span>{offerNumber}.</span>
                {/if}            
                <a href={offerLink}>{offerName}</a> - {offerTitle}
            </p>   
        </div>

        <div style="display: flex; align-items: center;">
            {#each {length: Math.floor(offerStar)} as _, i}
                <img style="width:auto; height:35px;" src="images/star.png" alt="star" />
            {/each}
        
            {#if offerStar % 1 !== 0}
                <img 
                    style="width:auto; height:35px; clip-path: inset(0 calc({(1 - (offerStar % 1)) * 100}% + 1px) 0 0);" 
                    src="images/star.png" 
                    alt="partial star" 
                />
            {/if}
        
            <p class="offer-review">( {offerReview} reviews)</p>
        </div>
        
        <img src={offerImage} alt="bonus" />
        <div class="text">        
            <div>{@html offerSummary}</div>
            <a href={offerLink} class="subscribe">Learn More {offerName}</a>
        </div>
    {/if}
</section>

<style>
    .new-arrivals{
        margin-top: -6%;
        font-size: 26px;
        line-height: 36px;
        font-weight: 700;
        color:red;
        text-transform: uppercase;
        margin-bottom: -6%;
        
    }
    a{
        display: inline-block;
        color: #007bff; /* Text color set to white for contrast */
        display: inline-block;
        font-size: 26px;
        line-height: 36px;
        font-weight: 700;
        letter-spacing: 0.5px;
        margin-bottom: 0; /* Remove bottom margin to align better */
        text-decoration: none;

    }
    .number-title {
    display: flex;
    flex-direction: row;
    align-items: center; /* Aligns number and title in the middle */
    }

    span {
        display: inline-block;
        font-size: 26px;
        font-weight: 700;
        color: #ffffff; /* Text color set to white for contrast */
        background-color: #000000; /* Black background */
        padding: 4px 8px; /* Increase horizontal padding for better look */
        margin-right: 10px; /* Add some spacing between number and title */
        font-size: 80%;

    }

    .title {
        display: inline-block;
        font-size: 26px;
        line-height: 36px;
        font-weight: 700;
        color: rgb(51, 51, 51);
        letter-spacing: 0.5px;
        margin-bottom: 0; /* Remove bottom margin to align better */
    }
    .bonus-section {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding-top: 60px;
    }
    img {
        width: 100%;
        margin-bottom: 26px;
    }

    .text {
        font-size: 24px;
        line-height: 40px;
        font-weight: 300;
        gap: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .offer-review{
        font-size: 24px;
        line-height: 40px;
        font-weight: 300;
        margin-bottom: 26px;
    }



    form {
        width: 100%;
    }

    .row {
        display: flex;
        width: 100%;
        gap: 30px;
        margin-bottom: 50px;
    }

    input {
        width: 50%;
        font-size: 1.0em;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: left;
    }

    .subscribe {
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        background-color: #f31200;
        border: 1px solid red;
        padding: .4em;
        border-bottom: 8px solid #c40202;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 24px;
        text-decoration: none;
        
    }
</style>
