<section class="trust-section">
    <p class="quick-links"></p>
    <div class="links">
        <a href="https://giftst.smartlifestyletrending.com/info/policy.php">
            <p>Privacy Policy</p>
        </a>
        <a href="https://giftst.smartlifestyletrending.com/info/terms.php">
            <p>Terms And Conditions</p>
        </a>
        <a href="https://giftst.smartlifestyletrending.com/info/about-us.php">
            <p>About Us</p>
        </a>
        <a href="https://giftst.smartlifestyletrending.com/info/impressum.php">
            <p>Impressum</p>
        </a>
        <a href="https://giftst.smartlifestyletrending.com/info/contact-us.php">
            <p>Contact</p>
        </a>
    </div>
  </section>

<style>
    .trust-section {
        display: flex;
        flex-direction: column;
    }

    .links {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-top: 16px;
        width: 100%;
    }

    p.quick-links {
        font-size: 1.5rem;
        font-weight: 600;
        color: #333;
        letter-spacing: .5px;
    }

    a {
        color: #007bff;
        text-decoration: none;
        font-size: 24px;
        font-weight: 300;
    }

    @media (max-width: 1200px) {
        a {
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }

        .links {
            gap: 8px;
        }
    }

    @media (max-width: 770px) {
        a {
            font-size: 14px;
            line-height: 18px;
            text-align: center;
        }

        .links {
            gap: 8px;
        }
    }
</style>