<script>
    export let text = "Text";  
    export let image = "";  
    export let color = "blue";
    export let href = "";
</script>

<a href={href} target="_blank">
    <button class="social-media-btn" style="background-color: {color};">
        <img src={image} />
        <p>{text}</p>
    </button>
</a>


<style>
    a {
        display: inline-block;
        flex: 1;
    }
    
    .social-media-btn {
        width: 100%;
        box-sizing: border-box;
        border-radius: 0px;
        border: none;
        cursor: pointer;
        font-size: 11px;
        height: 32px;
        line-height: 32px;
        padding: 0 8px;
        position: relative;
        text-align: center;
        top: 0;
        vertical-align: top;
        white-space: nowrap;
        transition: opacity 0.2s ease-in, top 0.2s ease-in;
    }

    .social-media-btn:hover {
        opacity: 0.8;
        top: -4px;  
    }

    img {
        display: inline-block;
        height: 16px;
        width: 16px;
        position: relative;
        top: 8px;
        vertical-align: top;
    }

    p {
        transition: all 0.2s ease-in;
        color: #fff;
        display: inline-block;
        font-weight: 500;
        letter-spacing: 0.5px;
        min-width: 60px;
        opacity: 1;
        padding: 0 6px;
        position: relative;
        vertical-align: top;
    }

    @media (max-width: 770px) {
        p {
            display: none;
        }
    }
</style>