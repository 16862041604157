<script>
    export let offerNumber, offerName, offerLink, offerTitle, offerReview, offerStar, offerImage, offerSummary;

    if(offerStar > 5){ offerStar = 5;}
</script>

<section class="quick-section">
   
        <div class="number-title">
            <p class="title">
                {#if offerNumber > 0}
                    <span>{offerNumber}.</span>
                {/if}
                <a href={offerLink}>{offerName}</a> - {offerTitle}
            </p>   
        </div>

</section>

<style>
    a {
        display: inline-block;
        color: #007bff;
        font-size: 16px;
        line-height: 36px;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin-bottom: 0;
        text-decoration: none;
        padding-top: 0px;

    }

    .quick-links {
        font-size: 16px;
        font-weight: 400;
        color: rgb(51, 51, 51);
        margin-bottom: 20px;
    }

    .number-title {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    span {
        padding-top: 0px;

    }

    .title {
        display: inline-block;
        padding-top: 0px;

        font-size: 14px;
        line-height: 36px;
        font-weight: 400;
        color: rgb(51, 51, 51);
        letter-spacing: 0.5px;
        margin-bottom: 0;
        /* background-color: yellow;; */
    }

    .quick-section {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding-top: 0px;

    }
</style>