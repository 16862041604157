<script>
    import SocialMedia from '../socialMedia/SocialMedia.svelte';
    export let totalItems;
</script>

<header>
    <p class="advertorial">Advertorial</p>
    <div class="image-container">
        <img src="./public/images/fav.png" alt="logo" style="height: 175px; margin-bottom: -50px; margin-top: -50px" />
        <!-- <img src="https://giftst.smartlifestyletrending.com/Image/logo4.png?v=1" alt="logo" /> -->
    </div>

    <div class="categories">
        <p class="blue">The Trend</p>
        <p class="red"><b>HOT DEALS</b></p>
    </div>

    <p class="title">{totalItems} Hottest Cool Gadgets That Are Going to Sell out This October</p>

    <div class="author">
        <img src="https://giftst.smartlifestyletrending.com/toptrendsm_files/author1small.png" alt="author" />
        <p class="name">by Douglas Jones</p>
        <p class="date">- October 1st, 2024</p>
    </div>

    <div class="social-media">
        <SocialMedia text="900" image="https://platform-cdn.sharethis.com/img/facebook.svg" color="#4267B2" href="https://www.facebook.com/" />
        <SocialMedia text="684" image="https://platform-cdn.sharethis.com/img/twitter.svg" color="#000000" href="https://twitter.com/" />
        <SocialMedia text="908" image="https://platform-cdn.sharethis.com/img/messenger.svg" color="#448AFF" href="https://www.messenger.com/" />
        <SocialMedia text="805" image="https://platform-cdn.sharethis.com/img/whatsapp.svg" color="#25d366" href="https://www.whatsapp.com/" />
        <SocialMedia text="704" image="https://platform-cdn.sharethis.com/img/pinterest.svg" color="#CB2027" href="https://www.pinterest.com/" />
    </div>
</header>

<style>
    header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 32px;
    }

    .advertorial {
        font-weight: 150;
        font-size: 23px;
    }

    .image-container {
        width: 100%;
        margin-top: 8px;
    }

    .categories {
        display: flex;
        gap: 4px;
        width: 100%;
        margin: 8px 0 15px;
    }

    .categories p {
        font-size: 16px;
        text-align: center;
        line-height: 25px;
        height: 25px;text-transform: uppercase;
        color: #fff;
        width: auto;
        display: inline-block;
        padding: 0 15px;
    }

    .blue {
        background-color: #2083e8;
    }

    .red {
        background-color: #CC0C39;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 700;
    }

    .author {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 4px;
        margin: 10px 0 20px;
    }

    .author img {
        margin-right: 2px;
    }

    .name {
        color: #333;
        font-weight: 700;
        font-size: 80%;
    }

    .date {
        color: #a4a4a4;
        font-size: 80%;
    }

    .social-media {
        display: flex;
        text-align: center;
        transition: o 0.2s ease-in, p 0.2s ease-in, a 0.2s ease-in, c 0.2s ease-in, i 0.2s ease-in, t 0.2s ease-in, y 0.2s ease-in;
        font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;
        direction: ltr;
        width: 100%;
        gap: 8px;
        margin-bottom: 24px;
    }
</style>