<footer>
    <p>
        <b class="">HEALTH DISCLAIMER:</b>&nbsp;This website is not intended to provide medical
        advice or to take the place of medical advice and treatment from your personal physician.
        Visitors are advised to consult their own doctors or other qualified health professional
        regarding the treatment of medical conditions. The author shall not be held liable or
        responsible for any misunderstanding or misuse of the information contained on this site or for
        any loss, damage, or injury caused, or alleged to be caused, directly or indirectly by any
        treatment, action, or application of any food or food source discussed in this website. The U.S.
        Food and Drug Administration have not evaluated the statements on this website. The information
        is not intended to diagnose, treat, cure, or prevent any disease.
    </p>
    <p>MARKETING DISCLOSURE: This website is a market place. As such you should know
        that the owner has a monetary connection to the product and services advertised on the site. The
        owner receives payment whenever a qualified lead is referred but that is the extent of it.</p>
    <p>ADVERTISING DISCLOSURE: This website and the products & services referred to
        on the site are advertising marketplaces. This website is an advertisement and not a news
        publication. Any photographs of persons used on this site are models. The owner of this site and
        of the products and services referred to on this site only provides a service where consumers
        can obtain and compare. </p>


    <p class="info">All the information on this website is published in good faith and for
        general information purpose only.</p>

    <span>
        Affiliate Disclosure: Smart Trends Now is a market place. As such you should know that Smart Trends Now
has a monetary connection to the product and services advertised on the site. The owner receives payment whenever a qualified lead is referred but that is the extent of it. This does not affect your overall experience, we truly provide the best possible deals we've found for every product listed.
    </span>

    <span>Trademarks used on our website are the property of their respective owners and it is not intended that our website or our services should implicitly or explicitly endorse them. the results and comments are illustrative and you may not achieve the same results when using these products.
        . We provide feedback on products and services through in-depth research and experienced editors. We are an independent company and the opinions expressed here are our own. This website is an independent professional comparison and review site that is supported by referral fees for the website and products.</span>

    <div>
        <p>©2024 SMARTTRENDSNOWS.COM All rights reserved.</p>
    </div>
</footer>

<style>
    footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        padding: 30px 0 20px;
        color: #6c757d;
        font-size: 11.7px;
        line-height: 40px;
        font-weight: 300;
        margin-bottom: 1rem;
        text-align: center;
    }

    .info {
        color: rgb(51, 51, 51);
    }

    span {
        color: rgb(108, 117, 125);
        font-size: 14px;
        font-weight: 400;
        line-height: 20.5px;
    }

    @media (max-width: 1200px) {
        footer {
            line-height: 28px;
        }

        span {
            font-size: 12px;
        }
    }
</style>